import React, { useState } from "react";
import axios from "axios";
import "./Chatbot.css"; // Importing CSS file

function Chatbot() {
  const [question, setQuestion] = useState("");
  const [response, setResponse] = useState("");

  const handleInputChange = (event) => {
    setQuestion(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const result = await axios.get(
        `https://ai.demoflair.com/ai?text=${encodeURIComponent(question)}`
      );
      setResponse(result.data);
    } catch (error) {
      console.error("Error sending message:", error);
      setResponse("Error sending message.");
    }
  };

  return (
    <div className="chatbot-container">
      <div className="chatbot-form-container">
        <form onSubmit={handleSubmit} className="chatbot-form">
          <input
            type="text"
            value={question}
            onChange={handleInputChange}
            className="chatbot-input"
            placeholder="Ask your question..."
          />
          <button type="submit" className="chatbot-submit-button">
            Ask Cloudflare
          </button>
        </form>
      </div>
      {response && <div className="chatbot-response">{response}</div>}
    </div>
  );
}

export default Chatbot;
